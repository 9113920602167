.TopPages {
  margin: auto;
  max-width: 750px;
  padding-top: 115px;
  margin-bottom: 150px;
}

.header {
  margin-bottom: 1.5rem;
  font-family: "nobel-bold",Helvetica,Arial,sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  text-align: center;
  font-size: 16px;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0 40px;
}

.listItem {
  border-top: 1px solid black;
  text-align: left;
  letter-spacing: 2px;
}

.link {
  display: block;
  color: #000;
  width: 100%;
  white-space: nowrap;
  padding: 20px 5px;
  text-decoration: none;
  position: relative;
  box-sizing: border-box;
}

.index {
  font-family: "pakt", "nobel-regular", Helvetica, Arial, sans-serif;
  display: inline-block;
  line-height: 16px;
  vertical-align: middle;
  font-size: 22px;
}

.label {
  font-family: "nobel-light",Helvetica,Arial,sans-serif;
  text-transform: uppercase;
  font-weight: 200;
  display: inline-block;
  color: black;
  line-height: 16px;
  vertical-align: middle;
  font-size: 17px;
  padding-left: 10px;
}

.arrow {
  text-align: right;
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  position: absolute;
  right: 10px;
  line-height: 17px;
  -webkit-transform: scaleX(0.75);
  -ms-transform: scaleX(0.75);
  transform: scaleX(0.75);
  -webkit-transform: scaleX(0.75);
  -ms-transform: scaleX(0.75);
}