/* Nobel Light */
@font-face {
  font-family: "nobel-light";
  src: url("./nobel/nobel-light.woff") format("woff");
}

/* Nobel Book */
@font-face {
  font-family: "nobel-book";
  src: url("./nobel/nobel-book.woff") format("woff");
}

/* Nobel Regular */
@font-face {
  font-family: "nobel-regular";
  src:  url("./nobel/nobel-regular.woff") format("woff");
  font-style: normal;
}

/* Nobel Bold */
@font-face { 
  font-family: "nobel-bold";
  src: url("./nobel/nobel-bold.woff") format("woff");
}

/* Pakt */
@font-face { 
  font-family: "pakt";
  src: url("./pakt/pakt.woff") format("woff");
}
